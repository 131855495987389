import { Box, Typography, Button, Stack, Autocomplete, TextField } from "@mui/material"
import SettingsIcon from '@mui/icons-material/Settings';
import Modal from '@mui/material/Modal';
import { useState, useRef, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
// import { Divider } from 'primereact/divider';
import "../styles/cuttoff.css"
import { Slider } from "primereact/slider";
import { InputText } from "primereact/inputtext";
import Divider from '@mui/material/Divider';

// import { Button } from 'primereact/button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SendIcon from '@mui/icons-material/Send';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import TableChartIcon from '@mui/icons-material/TableChart';

import { Dropdown } from 'primereact/dropdown';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { updateApiData, updateFormPayload, updateModalOpen, updateNewMemberData, updateNomineeType, updateReceiptIssuedType, updateWithdrawalType, updatecutoffData } from "../redux/executiveReducer";

import { RadioButton } from "primereact/radiobutton";

import "../styles/form.css"
import 'primereact/resources/primereact.min.css'; // Import the PrimeReact styles
import 'primeicons/primeicons.css'
import { InputNumber } from "primereact/inputnumber";

import CustomAxios from "../utils/CustomAxios"
import MuiInput from "../elements/Mui/mui_input";
import MuiAutocomplete from "../elements/Mui/mui_Autocomplete";
import MuiDatePicker from "../elements/Mui/mui_date_picker";
import MuiDaterange from "../elements/Mui/mui_data_range.js";


export const Form = (params) => {
    // let api_baseUrl = `${process.env.REACT_APP_OASIS_BE_URL}oasis/`
    
    // const [receiptType, setReceiptType] = useState('');
    const [api_endPoint,setApi_endPoint] = useState("")
    const newMemberData = useSelector(state=>state.executive.newMemberData)
    const [applyDisabled,setApplyDisabled]=useState(false)
    
    // console.log(params,newMemberData)
    useEffect(()=>{
        setApplyDisabled(false)
        setError("")
        if(params.api){
            //console.log("---coming inside----",params.api)
            switch (params.api){
                case 'CO':
                    setApi_endPoint("cut-off/")
                    break;
                case 'NMC':
                    setApi_endPoint("newMember/")
                    break;
                case 'RI':
                    //console.log()
                    setApi_endPoint("receipt-issued/")
                    break;
                case 'WD':
                    //console.log("withdrawal summery report")
                    setApi_endPoint("WithdrawelSummery/")
                    break;
                case 'AM':
                    setApi_endPoint("ageingMember/");
                    break;
                case 'NS':
                    setApi_endPoint("nomineeStatus/");
                    break;
                case 'BAS':
                    setApi_endPoint("bulk_acc_statement");
                    break;

            }
        }
    },[params.api])
    const fullDiv = useRef(null)
    const [dates, setDates] = useState([null,null]);
    const [memberRange, setMemberRange] = useState([0, 0])
    const [subsRange, setSubsRange] = useState([0, 0])
    const [shareRange, setShareRange] = useState([0, 0])
    const [ageRange, setAgeRange] = useState([0, 150])
    const [sex, setSex] = useState(null)
    const [race, setRace] = useState(null)
    const [photoOption, setPhotoOption] = useState(null)
    const [noMailer, setNoMailer] = useState(null)
    const [deceased, setDeseased] = useState(null)
    const [dispAction, setDispAction] = useState(null)
    const [director, setDirector] = useState("")
    const [observer, setOserver] = useState("")
    const [delegate, setDelegate] = useState("")
    const [company, setCompany] = useState("")
    const [junior, setJunior] = useState("")
    const [terminated, setTerminated] = useState("")
    const [geography, setGeography] = useState('state')
    const [geographyFilter, setGeographyFilter] = useState('ALL')
    const [geographyFilterArray, setGeographyFilterArray] = useState([])
    const [cutoffData, setCutoffData] = useState([])
    // const [withdrawalType,setWithdrawalType]=useState("")
    const [ageingOption,setAgeingOption]=useState("")
    // const [nomineeStatus,setNomineeStatus]=useState("")
    const [cutoffDate,setCutoffDate]=useState([null])
    const receiptIssuedType= useSelector(state=>state.executive.receiptIssuedType)
    const nomineeStatusType=useSelector(state=>state.executive.NomineeType)
    const withdrawalType=useSelector(state=>state.executive.withdrawalType)
    const [error,setError]=useState("")
    const [ranger,setRanger]=useState(false)
    const [click,  setClick] = useState(true)

    const dispatch = useDispatch()
    
    console.log("geo",  geographyFilterArray)
    const [states, setStates] = useState([])
    const [estates, setEstates] = useState([])
    const [postalCodes, setPostalCodes] = useState([])

    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];
    const sexArray = [
        { name: "All", code: "" },
        { name: "Male", code: "M" },
        { name: "Female", code: "F" }
    ]
    const raceArray = [
        { name: "All", code: "" },
        { name: 'I', code: "I" },
        { name: "M", code: "M" },
        { name: "C", code: "C" },
        { name: "O", code: "O" }
    ]
    const photoOptionArray = [
        { name: "All", code: "" },
        { name: "Yes", code: "Y" },
        { name: "No", code: "N" }
    ]
    const MemberStatusArray = [
        { name: "All", code: "" },
        { name: "Yes", code: 1 },
        { name: "No", code: 0 }
    ]
    
    const geographyArray = [
        { name: "State", code: "state" },
        { name: "Estate", code: "estate" },
        { name: "Postal Code", code: "postal" }
    ]
    const [memberMaxMin,setMemberMaxMin] = useState([0,0])
    const [shareMaxMin,setShareMaxMin] = useState([0,0])
    const [subMaxMin,setMaxMin] = useState([0,0])
    let api_count = 0
    useEffect(()=>{
        const fetchData = async () => {
            if (api_count === 0) {
                const [response1, response2] = await Promise.all([CustomAxios.get('oasis/initialData/'), CustomAxios.get('oasis/form_details')])
                if(response1.data) {
                  api_count++
                  setPostalCodes(response1.data.postal_codes)
                  setMemberRange(response1.data.memberids)
                  setShareRange(response1.data.shs)
                  setSubsRange(response1.data.subs)
                  setMemberMaxMin(response1.data.memberids)
                  setShareMaxMin(response1.data.shs)
                  setMaxMin(response1.data.subs)
                }
                if(response2.data) {
                  const states = response2.data.stateList.map(state => state.statename)
                  setEstates(response2.data.estateList.map(estate => estate.estatename))
                  setStates(states)
                  setGeographyFilterArray(states)
                }
            }
      }
      fetchData()
    },[])
    //old working code
    // useEffect(() => {
    //     // onchange of geograpgy we are restting the selected option of geograph array filter
    //     setGeographyFilter('')
    //     if (geography == 'state') {
    //         setGeographyFilterArray(states)


    //     }
    //     else if (geography == 'estate') {
    //         setGeographyFilterArray(estates)
    //     }
    //     else if (geography == 'postal') {
    //         setGeographyFilterArray(postalCodes)
    //     }

    // }, [geography])
    
    //new working code
    useEffect(() => {
        // Resetting the selected option of geography filter
        setGeographyFilter('All');
        
        let options = [];
        if (geography === 'state') {
            options = states;
        } else if (geography === 'estate') {
            options = estates;
        } else if (geography === 'postal') {
            options = postalCodes;
        }
        setGeographyFilterArray(options)
        
    }, [geography]);
   
   
    const clearForm = () => {
        setDates(null)
        setMemberRange(memberMaxMin)
        setShareRange(shareMaxMin)
        setSubsRange(subMaxMin)
        setAgeRange([0, 150])
        setSex({ name: "All", code: "" })
        setRace({ name: "All", code: "" })
        setPhotoOption({ name: "All", code: "" })
        setNoMailer({ name: "All", code: "" })
        setDeseased({ name: "All", code: "" })
        setDispAction({ name: "All", code: "" })
        setDirector({ name: "All", code: "" })
        setOserver({ name: "All", code: "" })
        setDelegate({ name: "All", code: "" })
        setCompany({ name: "All", code: "" })
        setJunior({ name: "All", code: "" })
        setGeography("state")
        setGeographyFilter("All")
        setTerminated({ name: "All", code: "" })
        dispatch(updateNomineeType(""))
        dispatch(updateReceiptIssuedType(""))
        dispatch(updateWithdrawalType(""))
   
    }
    useEffect(()=>{

        clearForm()
    },[api_endPoint])
    
    const handlMemberSliderChange = (e) => {
        const [newValue1, newValue2] = e;
    
        // Check if the range handles are crossing each other
        if (newValue1 > newValue2) {
          // Swap the values to prevent passing through
          setMemberRange([newValue2, newValue1]);
        } else {
          setMemberRange([newValue1, newValue2]);
        }
      };
      const handleShareRange = (e) => {
        const [newValue1, newValue2] = e;
    
        // Check if the range handles are crossing each other
        if (newValue1 > newValue2) {
          // Swap the values to prevent passing through
          setShareRange([newValue2, newValue1]);
        } else {
          setShareRange([newValue1, newValue2]);
        }
      };
      const handleSubsRange = (e) => {
        const [newValue1, newValue2] = e;
    
        // Check if the range handles are crossing each other
        if (newValue1 > newValue2) {
          // Swap the values to prevent passing through
          setSubsRange([newValue2, newValue1]);
        } else {
          setSubsRange([newValue1, newValue2]);
        }
      };
      const handleAgeRange = (e) => {
        const [newValue1, newValue2] = e;
    
        // Check if the range handles are crossing each other
        if (newValue1 > newValue2) {
          // Swap the values to prevent passing through
          setAgeRange([newValue2, newValue1]);
        } else {
          setAgeRange([newValue1, newValue2]);
        }
      };
    const memberArrange = (ind, val) => {
        const member = [...memberRange]
        member[ind] = val
        setMemberRange(member)


    }
    const shareArrange = (ind, val) => {
        const share = [...shareRange]
        share[ind] = val
        setShareRange(share)

    }
    const subsArrange = (ind, val) => {
        const subs = [...subsRange]
        subs[ind] = val
        setSubsRange(subs)

    }
    const ageArrange = (ind, val) => {
        const age = [...ageRange]
        age[ind] = val
        setAgeRange(age)

    }
    function padZero(value) {
        // Add leading zero if the value is less than 10
        return value < 10 ? `0${value}` : value;
    }
    const applyFilter = async (type) => {
        setApplyDisabled(true)
        let dateFormate
        console.log("type*********************", type)

        
        if(dates && dates.length>0){
            // dateFormate = dates.map(item => {
            //     const originalDate = new Date(item);
            //     const formattedDate = `${originalDate.getFullYear() }-${originalDate.getMonth() + 1}-${originalDate.getDate()}`;
            //     const [day, month, year] = formattedDate.split("/")
            //     return formattedDate
            // })

            dateFormate = dates.map((item, index, array) => {
                const originalDate = new Date(item);
                const year = originalDate.getFullYear();
                const month = String(originalDate.getMonth() + 1).padStart(2, '0');
                const day = String(originalDate.getDate()).padStart(2, '0');
            
                let formattedDate = `${year}-${month}-${day}`;
                if (index === array.length - 1) {
                    formattedDate += ' 23:59:59';
                }
                return formattedDate;
            });

        }
        else if(cutoffDate){
            dateFormate = cutoffDate?.map((item, index, array) => {
                const originalDate = new Date(item);
                const year = originalDate.getFullYear();
                const month = String(originalDate.getMonth() + 1).padStart(2, '0');
                const day = String(originalDate.getDate()).padStart(2, '0');
            
                let formattedDate = `${year}-${month}-${day}`;
                if (index === array.length - 1) {
                    formattedDate += ' 23:59:59';
                }
                return formattedDate;
            });

        }
        
        //console.log(dateFormate)
        console.log((geographyFilter!=="All"), geographyFilter)

        const payload = {
            mid: memberRange.every(value=>value === 0) || JSON.stringify(memberRange) === JSON.stringify(memberMaxMin)? [] : memberRange,
            type: type,
            dr: dateFormate,
            sh:  shareRange.every(value=>value === 0) || JSON.stringify(shareRange) === JSON.stringify(shareMaxMin)? [] : shareRange,
            sb: subsRange.every(value=>value === 0) || JSON.stringify(subsRange) === JSON.stringify(subMaxMin)? [] : subsRange,
            age: ageRange,
            sex: sex.code,
            race: race.code,
            po: photoOption.code,
            state: geography == 'state' ? geographyFilter!=="All"?geographyFilter:"" : "",
            estate: geography == 'estate' ? geographyFilter!=="All"?geographyFilter:"" : "",
            region: "",
            postal_code: geography == 'postal' ? geographyFilter!=="ALL"?geographyFilter:"" : "",
            receipt_type:receiptIssuedType,
            Withdrawel_type:withdrawalType,
            option:ageingOption,
            NomineeStatus:nomineeStatusType,
            nomailer:noMailer.code,
            deceased:deceased.code,
            dispAction:dispAction.code,
            director:director.code,
            observer:observer.code,
            delegate:delegate.code,
            company:company.code,
            junior:junior.code,
            terminated:terminated.code === 0?"N":terminated.code === 1?"Y":""

        };
        try {
            //console.log(api_baseUrl+api_endPoint)
            //console.log(payload)
            if(api_endPoint !== 'bulk_acc_statement') {

                const response = await CustomAxios.post(`oasis/${api_endPoint}`, payload);
                //console.log(response.data.data);
                if (response.data && response.data.data.length >= 1) {
                    dispatch(updateApiData({type:params.api,data:response.data.data}))
                    dispatch(updateModalOpen(false))
                    dispatch(updateFormPayload({type:params.api,payload:payload}))
                    // dispatch(updateReceiptIssuedType(receiptType))
                    // dispatch(updateNomineeType(nomineeStatus))
                    // dispatch(updateWithdrawalType(withdrawalType)):
                    setCutoffData(response.data.data);
                    setApplyDisabled(false)
                    //console.log("inside if condition")
                } else {
                    // Handle the case where the expected data is not available
                    setApplyDisabled(false)
                    //console.error('No data available for the selected date range. Please choose a different range or broaden your search.');
                    setCutoffData([]);
                    // dispatch(updateApiData({type:params.api,data:[]}))
                    setError( 'No data available for the selected date range. Please choose a different range or broaden your search.')
                }

           } else {
                async function startTaskAndListenForUpdates(apiEndPoint, payload) {
                    try {
                        // Step 1: Trigger the task
                        console.log('Starting the task...');
                        const response = await CustomAxios.post(`oasis/${apiEndPoint}_start_task/`, payload);

                        if (!response.data.task_id) {
                            console.error('Task ID not received from the server.');
                            return;
                        }
                    
                        const taskId = response.data.task_id;
                        console.log(`Task started with ID: ${taskId}`);

                        let url = new URL(process.env.REACT_APP_OASIS_BE_URL)
                        let hostname = url.hostname
                        let domain;
                        if (hostname.includes('.com')) {
                            domain = hostname.substring(0, hostname.lastIndexOf(".com") + 4);
                        } else {
                            domain = `${hostname}:${url.port}`;
                        }
                    
                        // Step 2: Establish WebSocket connection
                        const socket = new WebSocket(`wss://${domain}/ws/task-progress/${taskId}/`);
                    
                        socket.onopen = () => {
                            console.log('WebSocket connection established.');
                            // Send the task ID to the server to start receiving updates
                            socket.send(JSON.stringify({ task_id: taskId }));
                        };
                    
                        socket.onmessage = async (event) => {

                            const taskStatus = JSON.parse(event.data);

                            if (taskStatus.type === "ping") {
                                // Respond to the server's ping with a pong
                                console.log("Ping received from server. Sending pong...");
                                socket.send(JSON.stringify({ type: "pong" }));
                            } else {
                                console.log('Task update received:', taskStatus);
                                if (taskStatus.status === 'completed') {
                                    console.log('Task completed. Downloading PDF...');
                                    if(type == "download" || type== "downloademail") {
                                        const pdfResponse = await CustomAxios.get(`oasis/${apiEndPoint}_task_result/${taskId}/`, { responseType: 'blob' });
                                        if (pdfResponse.data) {
                                            // Create a URL for the PDF blob
                                            const fileURL = URL.createObjectURL(pdfResponse.data);
                                
                                            // Open the PDF in a new window/tab
                                            const win = window.open(fileURL, "_blank");
                                
                                            // Optionally, automatically trigger the print dialog in the new window
                                            win.onload = () => {
                                            win.focus();
                                            win.print();
                                            };
                                        }
                                    }
                                    
                                    setApplyDisabled(false)
                                    socket.close(); // Close the WebSocket connection
                                } else if (taskStatus.status === 'failed') {
                                    console.error('Task failed:', taskStatus.error);
                                    socket.close(); // Close the WebSocket connection
                                } else {
                                    console.log('Task in progress:', taskStatus.status);
                                }
                            }
                        };
                    
                        socket.onerror = (error) => {
                            console.error('WebSocket error:', error);
                        };
                    
                        socket.onclose = () => {
                            console.log('WebSocket connection closed.');
                        };
                    } catch (error) {
                        console.error('Error during task execution:', error);
                    }
                }
                startTaskAndListenForUpdates(api_endPoint, payload)
            }
        } catch (error) {
            // console.error('API Error:', error.code);
            setCutoffData([]);
            setApplyDisabled(false)
            setError( 'No results found with the current filters. Please adjust your filters or try a different search.')
            
        }


    }
    const handleDateChange = (newValue) => {
        setCutoffDate([newValue]);
      };

      const handleDaterangeChange = (newValue) => {
        setDates(newValue);
      };

      const formattedGeographyArray = geographyArray.map(option => ({
        label: option.name,
        value: option.code,
      }));


      const handleGeographyChange = (event, newValue) => {
        if (newValue) {
          setGeography(newValue.value);
        } else {
          setGeography('');
        }
      };

      console.log(geographyFilter)
      
    return (
        <Stack direction="row" className="fulldiv" sx={{justifyContent:"space-around",alignItems:"center",width:"100%"}} ref={fullDiv}>
                {api_endPoint!=="bulk_acc_statement"? params.formAlone?null:
                    <Box sx={{ width: "384px",height: "90vh", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>


                    <Typography sx={{ fontSize: "36px", fontWeight: 500, lineHeight: "40px", color: "#101010", }}>No records Found</Typography>
                    <Box sx={{ marginTop: "19px" }}>
                        <Typography sx={{ fontSize: "20px", fontWeight: 400, lineHeight: "28px", color: "#64748B" }}>To view records, please set up a Data Configure to populate the data by clicking the below icon.</Typography>
                    </Box>
                    {/* <SettingsIcon onClick={() => setVisible(true)} sx={{ fontSize: "64px", color: "#288d2c", cursor: "pointer" }} /> */}
                    </Box>
                    :<></>
                }
            <Box style={{ width: '790px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 0px 30px", borderRadius: "0px", paddingRight: "24px" }} >
                <Box sx={{ display: "flex", gap: "16px", alignItems: "center", paddingBottom: "24px", background: "#f2f6fc" }}>
                    <SettingsIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                    <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Filter to {api_endPoint!=="bulk_acc_statement"?"generate data":"Print Statement of Accounts"}</Typography>
                </Box>
                <Box sx={{ maxHeight: "596px", overflowY: "scroll", overflowX: "hidden", background: "#f2f6fc", paddingRight: "24px" }}>

                {
                            api_endPoint=='receipt-issued/' &&<>
                            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column",marginBottom:"16px" }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Receipt issues:</Typography>
                            

                        </Box>
                            <div className="flex flex-wrap gap-3" style={{display:"flex",gap:"24px"}}>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option1"  value="detailed" style={{display:"flex",alignItems:"center"}} onChange={(e) =>  dispatch(updateReceiptIssuedType(e.value))} checked={receiptIssuedType === 'detailed'} />
                                <label htmlFor="Option1" className="ml-2">Detailed</label>
                            </div>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option2"  value="summery" style={{display:"flex",alignItems:"center"}} onChange={(e) =>  dispatch(updateReceiptIssuedType(e.value))} checked={receiptIssuedType === 'summery'} />
                                <label htmlFor="Option2" className="ml-2">Summary</label>
                            </div>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option2"  value="specific_deposit" style={{display:"flex",alignItems:"center"}} onChange={(e) =>  dispatch(updateReceiptIssuedType(e.value))} checked={receiptIssuedType === 'specific_deposit'} />
                                <label htmlFor="Option2" className="ml-2">Specific Deposit</label>
                            </div>
                        </div>
                        <Box sx={{ marginBottom: "24px", marginTop: "24px" }}>
                        <Divider variant="middle" />
                    </Box></>
                        }{
                            api_endPoint=="nomineeStatus/" &&<>
                            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column",marginBottom:"16px" }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Nominee Status:</Typography>
                            

                        </Box> <div className="flex flex-wrap gap-3" style={{display:"flex",gap:"24px"}}>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option1"  value="withNominee" style={{display:"flex",alignItems:"center"}} onChange={(e) => dispatch(updateNomineeType((e.value)))} checked={nomineeStatusType === 'withNominee'} />
                                <label htmlFor="Option1" className="ml-2">With Nominee</label>
                            </div>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option2"  value="withoutNominee" style={{display:"flex",alignItems:"center"}} onChange={(e) => dispatch(updateNomineeType((e.value)))} checked={nomineeStatusType === 'withoutNominee'} />
                                <label htmlFor="Option2" className="ml-2">Without Nominee</label>
                            </div>
                            
                        </div>
                        </>
                            }

                    {
                            api_endPoint=='WithdrawelSummery/' &&<>
                            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column",marginBottom:"16px" }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Receipt issues:</Typography>
                            

                        </Box>
                            <div className="flex flex-wrap gap-3" style={{display:"flex",gap:"24px"}}>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option1"  value="detailed" style={{display:"flex",alignItems:"center"}} onChange={(e) => dispatch(updateWithdrawalType((e.value)))} checked={withdrawalType === 'detailed'} />
                                <label htmlFor="Option1" className="ml-2">Detailed</label>
                            </div>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option2"  value="summery" style={{display:"flex",alignItems:"center"}} onChange={(e) => dispatch(updateWithdrawalType((e.value)))} checked={withdrawalType === 'summery'} />
                                <label htmlFor="Option2" className="ml-2">Summary</label>
                            </div>
                            
                           
                        </div>
                        <Box sx={{ marginBottom: "24px", marginTop: "24px" }}>
                        <Divider variant="middle" />
                    </Box></>
                        }
                        {
                            api_endPoint=='ageingMember/' &&<>
                            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column",marginBottom:"16px" }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Ageing Member Report:</Typography>
                            

                        </Box>
                            <div className="flex flex-wrap gap-3" style={{display:"flex",gap:"24px"}}>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option1"  value="share" style={{display:"flex",alignItems:"center"}} onChange={(e) => setAgeingOption(e.value)} checked={ageingOption === 'share'} />
                                <label htmlFor="Option1" className="ml-2">Share</label>
                            </div>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option2"  value="subscription" style={{display:"flex",alignItems:"center"}} onChange={(e) => setAgeingOption(e.value)} checked={ageingOption === 'subscription'} />
                                <label htmlFor="Option2" className="ml-2">Subscription</label>
                            </div>
                            <div className="flex align-items-center" style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                <RadioButton inputId="Option2"  value="loan" style={{display:"flex",alignItems:"center"}} onChange={(e) => setAgeingOption(e.value)} checked={ageingOption === 'loan'} />
                                <label htmlFor="Option2" className="ml-2">Loan</label>
                            </div>
                            
                           
                        </div>
                        <Box sx={{ marginBottom: "24px", marginTop: "24px" }}>
                        <Divider variant="middle" />
                    </Box></>
                        }


                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" ,marginTop:'1rem'}}>
                        {(api_endPoint=="cut-off/" || api_endPoint=="bulk_acc_statement")&&<Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                            {/* <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Cut off Date:</Typography> */}
                            <div className="card flex justify-content-center p-inputgroup">
                            
                                {/* <Calendar 
                                className="p-inputgroup" 
                                value={cutoffDate[0]} 
                                onChange={(e) => setCutoffDate([e.value])} 
                                style={{ width: "315px", height: "36px",background: 'transparent', border: 'none'  }} 
                                dateFormat="dd-mm-yy" 
                                placeholder='dd-mm-yyyy'  
                                showIcon 
                                /> */}
                                <MuiDatePicker
                                    label={api_endPoint!=="bulk_acc_statement"?"Cutoff Date":"Statement Year"}
                                    name="cutoffDate"
                                    value={cutoffDate[0]}
                                    onChange={handleDateChange}
                                    format={api_endPoint!=="bulk_acc_statement"?"DD-MM-YYYY":"YYYY"}
                                    views={api_endPoint!=="bulk_acc_statement"?['year', 'month', 'day']:['year']}
                                    style={{ width: "315px", height: "36px", background: 'transparent', border: 'none' }}
                                    isSubmitted={false}
                                    validationErrMsg=""
                                    />
                            </div>

                        </Box>}
                        {api_endPoint=="cut-off/"&&<Box sx={{ display: "flex", alignSelf: "end" }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: "600", lineHeight: "24px" }}>OR</Typography>
                        </Box>}
                        {(api_endPoint=="cut-off/" || api_endPoint=="newMember/" || api_endPoint=="receipt-issued/" || api_endPoint=="WithdrawelSummery/" || api_endPoint=="ageingMember/")&&
                        <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                            {/* <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Date Range:</Typography> */}
                            <div className="card flex justify-content-center">
                                {/* <Calendar 
                                className="p-inputgroup" 
                                value={dates} 
                                onChange={(e) => setDates(e.value)} 
                                selectionMode="range" 
                                style={{ width: "315px", height: "36px" }} 
                                readOnlyInput dateFormat="dd-mm-yy"
                                placeholder='dd-mm-yyyy - dd-mm-yyyy' 
                                showIcon
                                /> */}
                                    <MuiDaterange
                                    label="Date Range"
                                    name="dateRange"
                                    value={dates}
                                    onChange={handleDaterangeChange}
                                    format="DD-MM-YYYY"
                                    style={{ width: "315px", height: "36px" }}
                                    />
                            </div>

                        </Box>}
                        
                    </Box>
                    {(api_endPoint=="cut-off/" || api_endPoint=="newMember/" || api_endPoint=="ageingMember/" || api_endPoint=="nomineeStatus/"||api_endPoint=="bulk_acc_statement")&&<>
                    <Box sx={{ marginBottom: "24px", marginTop: "24px" }}>
                        <Divider variant="middle" />
                    </Box>
                    
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Membership No:</Typography>
                            </Box>
                            <Slider value={memberRange} onChange={(e) => handlMemberSliderChange(e.value)} className="custom-slider" style={{ width: "680px" }} range  max={memberMaxMin[1]} />
                            {/* <Box sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-between" }}>
                                <InputText style={{ width: "191px", height: "36px" }} value={memberRange[0]} onChange={(e) => memberArrange(0, e.target.value)} />
                                <InputText style={{ width: "191px", height: "36px" }} value={memberRange[1]} onChange={(e) => memberArrange(1, e.target.value)} />
                            </Box> */}
                            <Box sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-between" }}>
                                <MuiInput style={{ width: "191px", height: "36px" }} value={memberRange[0]} onChange={(e) => memberArrange(0, e.target.value)} />
                                <MuiInput style={{ width: "191px", height: "36px" }} value={memberRange[1]} onChange={(e) => memberArrange(1, e.target.value)} />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Share Balance Range:</Typography>
                            </Box>
                            <Slider value={shareRange} onChange={(e) => handleShareRange(e.value)} style={{ width: "680px" }} range max={shareMaxMin[1]} />
                            {/* <Box sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-between" }}>
                                <InputText style={{ width: "191px", height: "36px" }} value={shareRange[0]} onChange={(e) => shareArrange(0, e.target.value)} />
                                <InputText style={{ width: "191px", height: "36px" }} value={shareRange[1]} onChange={(e) => shareArrange(1, e.target.value)} />
                            </Box> */}
                             <Box sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-between" }}>
                                <MuiInput style={{ width: "191px", height: "36px" }} value={shareRange[0]} onChange={(e) => shareArrange(0, e.target.value)} />
                                <MuiInput style={{ width: "191px", height: "36px" }} value={shareRange[1]} onChange={(e) => shareArrange(1, e.target.value)} />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Subscription Balance Range:</Typography>
                            </Box>
                            <Slider value={subsRange} onChange={(e) => handleSubsRange(e.value)} style={{ width: "680px" }} range max={subMaxMin[1]} />
                            {/* <Box sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-between" }}>
                                <InputText style={{ width: "191px", height: "36px" }} value={subsRange[0]} onChange={(e) => subsArrange(0, e.target.value)} />
                                <InputText style={{ width: "191px", height: "36px" }} value={subsRange[1]} onChange={(e) => subsArrange(1, e.target.value)} />
                            </Box> */}
                            <Box sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-between" }}>
                                <MuiInput style={{ width: "191px", height: "36px" }} value={subsRange[0]} onChange={(e) => subsArrange(0, e.target.value)} />
                                <MuiInput style={{ width: "191px", height: "36px" }} value={subsRange[1]} onChange={(e) => subsArrange(1, e.target.value)} />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Age Range:</Typography>
                            </Box>
                            <Slider  value={ageRange} onChange={(e) => handleAgeRange(e.value)} style={{ width: "680px" }} range max={150} />
                            {/* <Box sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-between" }}>
                                <InputNumber  style={{ width: "191px", height: "36px" }} value={ageRange[0]} onValueChange={(e) => ageArrange(0, e.target.value)} />
                                <InputNumber  style={{ width: "191px", height: "36px" }} value={ageRange[1]} onValueChange={(e) => ageArrange(1, e.target.value)} />
                            </Box> */}
                            <Box sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-between" }}>
                                <MuiInput  style={{ width: "191px", height: "36px" }} value={ageRange[0]} onValueChange={(e) => ageArrange(0, e.target.value)} />
                                <MuiInput  style={{ width: "191px", height: "36px" }} value={ageRange[1]} onValueChange={(e) => ageArrange(1, e.target.value)} />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Sex:</Typography>

                                </Box> */}
                                {/* <Dropdown  
                                value={sex} 
                                onChange={(e) => setSex(e.value)} 
                                style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                options={sexArray} 
                                optionLabel="name" 
                                optionValue="code"
                                placeholder="All" /> */}
                                    <MuiAutocomplete
                                        value={sex}
                                        onChange={(event, newValue) => setSex(newValue)}
                                        options={sexArray}
                                        getOptionLabel={(option) => option?.name || ""}
                                        label="Sex"
                                        autoCompleteStyle={{ width: "218px", height: "36px" }} 
                                        />
                            </Box>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{}}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Race:</Typography>

                                </Box> */}
                                {/* <Dropdown 
                                 value={race} 
                                 onChange={(e) => setRace(e.value)} 
                                 style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                 options={raceArray}
                                 optionLabel="name" 
                                 optionValue="code"
                                 placeholder="All" 
                                 /> */}
                                <MuiAutocomplete 
                                 label="Race"
                                 value={race} 
                                 options={raceArray}
                                 onChange={(event, newValue) => setRace(newValue)} 
                                 autoCompleteStyle={{ width: "218px", height: "36px" }} 
                                 getOptionLabel={(option) => option?.name || ""}
                                 />
                            </Box>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Photo Option:</Typography>
                                </Box> */}
                                {/* <Dropdown  
                                    value={photoOption} 
                                    onChange={(e) => setPhotoOption(e.value)} 
                                    style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                    options={photoOptionArray} 
                                    optionLabel="name" 
                                    optionValue="code"
                                    placeholder="All"
                                 /> */}
                                 <MuiAutocomplete
                                    label='Photo Option'
                                    value={photoOption}
                                    onChange={(event, newValue) => setPhotoOption(newValue)}
                                    options={photoOptionArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px" }}
                                    />
                            </Box>


                        </Box>
                    </Box>
                    <Box sx={{ marginBottom: "24px", marginTop: "24px" }}>
                        <Divider variant="middle" />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#f2f6fc" }}>

                            <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px", color: "#101010" }}>Member Status</Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>No Mailer:</Typography>

                                </Box> */}
                                {/* <Dropdown  
                                 value={noMailer} 
                                 onChange={(e) => setNoMailer(e.value)}
                                 style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                 options={MemberStatusArray} 
                                 optionLabel="name" 
                                 optionValue="code"
                                 placeholder="All" 
                                 /> */}
                                 <MuiAutocomplete
                                    label="No Mailer"
                                    value={noMailer}
                                    onChange={(event, newValue) => setNoMailer(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px" }}
                                    />

                            </Box>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{}}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Deceased:</Typography>

                                </Box> */}
                                {/* <Dropdown  
                                value={deceased} 
                                onChange={(e) => setDeseased(e.value)}
                                style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                options={MemberStatusArray} 
                                optionLabel="name" 
                                optionValue="code"
                                placeholder="All" 
                                /> */}
                                <MuiAutocomplete
                                    label="Deceased"
                                    value={deceased}
                                    onChange={(event, newValue) => setDeseased(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px" }}
                                />

                            </Box>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                    <Typography
                                     sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Disipl. Action:</Typography>
                                </Box> */}
                                {/* <Dropdown  
                                value={dispAction} 
                                onChange={(e) => setDispAction(e.value)} 
                                style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                options={MemberStatusArray} 
                                optionLabel="name" 
                                optionValue="code"
                                placeholder="All" 
                                /> */}
                                <MuiAutocomplete
                                    label='Disipl. Action'
                                    value={dispAction}
                                    onChange={(event, newValue) => setDispAction(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }}
                                />
                            </Box>


                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Director:</Typography>
                                </Box> */}
                                {/* <Dropdown 
                                 value={director} 
                                 onChange={(e) => setDirector(e.value)} 
                                 style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                 options={MemberStatusArray} 
                                 optionLabel="name" 
                                 optionValue="code"
                                placeholder="All" 
                                /> */}
                                 <MuiAutocomplete
                                    label='Director'
                                    value={director}
                                    onChange={(event, newValue) => setDirector(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }}
                                />

                            </Box>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{}}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Observer:</Typography>

                                </Box> */}
                                {/* <Dropdown 
                                 value={observer} 
                                 onChange={(e) => setOserver(e.value)} 
                                 style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                 options={MemberStatusArray} 
                                 optionLabel="name" 
                                 optionValue="code"
                                 placeholder="All" 
                                 /> */}

                                  <MuiAutocomplete
                                    label='Observer'
                                    value={observer}
                                    onChange={(event, newValue) => setOserver(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }}
                                />

                            </Box>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Delegate:</Typography>
                                </Box> */}
                                {/* <Dropdown  
                                 value={delegate} 
                                 onChange={(e) => setDelegate(e.value)}
                                 style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                 options={MemberStatusArray} 
                                 optionLabel="name" 
                                 optionValue="code"
                                 placeholder="All" 
                                 /> */}
                              
                              <MuiAutocomplete
                                    label='Delegate'
                                    value={delegate}
                                    onChange={(event, newValue) => setDelegate(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }}
                                />

                            </Box>


                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Company:</Typography>

                                </Box> */}
                                {/* <Dropdown 
                                 value={company} 
                                 onChange={(e) => setCompany(e.value)} 
                                 style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                 options={MemberStatusArray} 
                                 optionLabel="name" 
                                 optionValue="code"
                                 placeholder="All" 
                                 /> */}
                                <MuiAutocomplete
                                    label='Company'
                                    value={company}
                                    onChange={(event, newValue) => setCompany(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }}
                                />

                            </Box>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{}}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Junior:</Typography>

                                </Box> */}
                                {/* <Dropdown  
                                value={junior}
                                onChange={(e) => setJunior(e.value)} 
                                style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }}
                                options={MemberStatusArray} 
                                optionLabel="name" 
                                optionValue="code"
                                placeholder="All" 
                                /> */}
                               <MuiAutocomplete
                                    label='Junior'
                                    value={junior}
                                    onChange={(event, newValue) => setJunior(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }}
                                />
                            </Box>
                            <Box sx={{ alignSelf: "start", display: "flex", gap: "8px", flexDirection: "column" }}>
                                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Terminated:</Typography>
                                </Box> */}
                                {/* <Dropdown  
                                value={terminated} 
                                onChange={(e) => setTerminated(e.value)} 
                                style={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }} 
                                options={MemberStatusArray} 
                                optionLabel="name" optionValue="code"
                                placeholder="All" 
                                /> */}
                                <MuiAutocomplete
                                    label='Terminated'
                                    value={terminated}
                                    onChange={(event, newValue) => setTerminated(newValue)}
                                    options={MemberStatusArray}
                                    getOptionLabel={(option) => option?.name || ""}
                                    autoCompleteStyle={{ width: "218px", height: "36px", display: "flex", alignItems: "center" }}
                                />

                            </Box>


                        </Box>

                    </Box></>}
                    <Box sx={{ marginBottom: "24px", marginTop: "24px" }}>
                        <Divider variant="middle" />
                    </Box>
                    <Box sx={{ display: "flex", gap: "24px", flexDirection: "column", paddingBottom: "24px" }}>
                        <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#f2f6fc" }}>

                            <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px", color: "#000000" }}>Geography</Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Choose any one option:</Typography>

                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                {/* <Dropdown  
                                value={geography} 
                                onChange={(e) => setGeography(e.value)} 
                                style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }} 
                                options={geographyArray} 
                                optionLabel="name"
                                optionValue="code"
                                placeholder="State" 
                                /> */}
                                 <MuiAutocomplete
                                    value={formattedGeographyArray.find(option => option.value === geography) || null}
                                    onChange={handleGeographyChange}
                                    autoCompleteStyle={{ width: "337px", height: "36px"}}
                                    options={formattedGeographyArray}
                                    getOptionLabel={(option) => option.label}
                                    placeholder="ALL"
                                />
                                                                
                                {/* <Dropdown  
                                value={geographyFilter} 
                                onChange={(e) => setGeographyFilter(e.value)} 
                                style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }}
                                options={geographyFilterArray}
                                placeholder="All" 
                                filter 
                                /> */}
                                <MuiAutocomplete
                                    value={geographyFilter}
                                    onChange={(event, newValue) => setGeographyFilter(newValue)}
                                    options={geographyFilterArray}
                                    autoCompleteStyle={{ width: "337px", height: "36px" }}
                                />

                            </Box>


                        </Box>

                    </Box>


                </Box>
                <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-24px",flexDirection:"column" }}>
                <Box sx={{alignSelf:"start",display:"flex",justifyContent:"space-between",width:'100%'}}>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", marginLeft: "30px" }}>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px" }} startIcon={ applyDisabled ? <i className="pi pi-spin pi-spinner" /> :<FileDownloadIcon />} onClick={() => applyFilter("download")} disabled={applyDisabled}>{api_endPoint!=="bulk_acc_statement"?'Apply':'Download'}</Button>
                        {api_endPoint==="bulk_acc_statement"?<>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px" }} startIcon={ applyDisabled ? <i className="pi pi-spin pi-spinner" /> :<SendIcon />} onClick={() => applyFilter("email")} disabled={applyDisabled}>Send Email</Button>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "160px", height: "48px" }} startIcon={ applyDisabled ? <i className="pi pi-spin pi-spinner" /> :<SendAndArchiveIcon />} onClick={() => applyFilter("downloademail")} disabled={applyDisabled}>Download and Send Email</Button>
                        </>
                            : null}
                        <Button variant="outlined" sx={{ borderColor: "#288d2c", color: "#288d2c", "&:hover": { borderColor: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<ClearAllIcon />} onClick={clearForm}>Clear All</Button>
                    </Box>    
                    {!params.formAlone?null:

                    <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "end", cursor: "pointer", textTransform: "none" }} onClick={()=>dispatch(updateModalOpen(false))}>

                        <ClearIcon />
                        <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}></Typography>
                        Close
                    </Box>}
                    

                </Box>
                <Typography sx={{alignSelf:"start", display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", marginLeft: "30px",color:"red" }}>{error}</Typography>
                </Box>
                

            </Box>


        </Stack>

    )
}